import cx from 'clsx'
import * as React from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'

const baseButton = [
  'appearance-none',
  'relative',
  'flex-1',
  'items-center',
  'px-2',
  'py-1',
  'border-t',
  'border-b',
  'border-x',
  'border-divider-light-gray',
  'bg-interactive-secondary',
  'shadow-button-secondary',
  'text-base',
  'font-semibold',
  'text-copy-primary',
  'hover:bg-interactive-secondary-hover',
  'active:bg-interactive-secondary-active',
  'focus:z-10',
  'focus:outline-none focus-visible:outline-none',
  'focus-visible:border-divider-dark-gray',
  'first:ml-0',
  'rounded-none',
  'first:rounded-l',
  'last:rounded-r',
].join(' ')
const activeClassname =
  '!bg-interactive-secondary-active text-interactive-primary !shadow-none !border-divider-light-blue'

interface ButtonProps {
  readonly children: React.ReactNode
  readonly index: number
  readonly onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  readonly controlled?: boolean
  readonly active?: boolean | null
  readonly selectedButtonIndex: number
  readonly setSelectedButtonIndex: (index: number) => void
  readonly tooltip?: React.ReactNode
  readonly className?: string
}

const Button = (props: ButtonProps): JSX.Element => {
  const { index, onClick, children, selectedButtonIndex, setSelectedButtonIndex, className } = props

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      delayShow: 300,
      placement: 'top',
    })

  const onButtonClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (typeof onClick === 'function') {
        onClick(event)
      }

      event.stopPropagation()
      event.preventDefault()

      setSelectedButtonIndex(index)
    },
    [onClick, setSelectedButtonIndex, index],
  )

  return (
    <button
      type="button"
      ref={setTriggerRef}
      className={cx(baseButton, className, {
        [activeClassname]: props.controlled ? props.active : index === selectedButtonIndex,
      })}
      onClick={onButtonClick}
    >
      {children}
      {visible && props.tooltip && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          {props.tooltip}
        </div>
      )}
    </button>
  )
}

const ButtonGroup = (props: {
  readonly id?: string
  readonly children: React.ReactElement | readonly React.ReactElement[]
  readonly defaultSelectedIndex?: number
  readonly controlled?: boolean
  readonly className?: string
  readonly sharedButtonProps?: {
    readonly [key: string]: unknown
  }
}): JSX.Element => {
  const { id, children, controlled, className, defaultSelectedIndex, sharedButtonProps } = props

  const [selectedButtonIndex, setSelectedButtonIndex] = React.useState(defaultSelectedIndex || 0)

  return (
    <span id={id} className={cx('z-1 relative flex flex-1 rounded', className)}>
      {React.Children.map(children, (child, index) => {
        return React.cloneElement(child, {
          ...sharedButtonProps,
          controlled,
          index,
          selectedButtonIndex,
          setSelectedButtonIndex,
        })
      })}
    </span>
  )
}

// note: these props don't really matter here since they're getting injected from ButtonGroup,
// but we have them defined here so TypeScript knows these props exist when we're using
// the ButtonGroup.Button components inside of the ButtonGroup
// eslint-disable-next-line functional/immutable-data
Button.defaultProps = {
  children: null,
  index: 0,
  onClick: () => {
    // void
  },
  selectedButtonIndex: -1,
  setSelectedButtonIndex: () => {
    // void
  },
  tooltip: '',
}

// eslint-disable-next-line functional/immutable-data
ButtonGroup.Button = Button

export default ButtonGroup
